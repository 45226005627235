<template>
  <div class="my-container">
    <div class="item tool-bar">
      <div class="tool-bar-item print-invisible">
<!--        <input id="chartFrom" type="date"  v-model="chartFrom" placeholder="日付を選択"/><label style="font-weight: bold;font-size: 15px">まで</label>-->
        <datepicker id="from" :value="chartToTmp" :language="ja"
                    v-model="chartToTmp" format="yyyy/MM/dd" bootstrap-styling placeholder="終了日付を選択" />
      </div>


      <div class="tool-bar-item print-invisible">
        <CButton size="sm" color="success mr-1" variant="outline" :pressed="dayCount == 1" @click="dayCount =1;">日</CButton>
        <CButton size="sm" color="success mr-1" variant="outline" :pressed="dayCount == 7" @click="dayCount =7;">週</CButton>
        <CButton size="sm" color="success mr-1" variant="outline" :pressed="dayCount == 30" @click="dayCount =30;">30日</CButton>
        <CButton size="sm" color="success mr-1" variant="outline" :pressed="dayCount == 90" @click="dayCount =90;">90日</CButton>
      </div>
      <div class="tool-bar-item print-invisible">
        <CButton size="sm" color="success ml-2 mr-1" variant="outline" :pressed="chartType == 'summary'" @click="chartType = 'summary'">サマリー</CButton>
        <CButton size="sm" color="success mr-1"      variant="outline" :pressed="chartType == 'ox'" @click="chartType = 'ox'">酸素濃縮装置</CButton>
        <CButton size="sm" color="success mr-1"      variant="outline" :pressed="chartType == 'pu'" @click="chartType = 'pu'">パルスオキシメータ</CButton>
        <CButton size="sm" color="success mr-1"      variant="outline" :pressed="chartType == 'bl'" @click="chartType = 'bl'">血圧計</CButton>
      </div>

      <div class="tool-bar-item print-invisible">
        <CButton size="sm" color="info"  class="ml-1" @click="csvDownload()" v-if="chartType!='summary'">CSV</CButton>
        <CButton size="sm" color="primary mr-1 ml-5" :pressed="false" @click="chartPrint">レポート印刷</CButton>
      </div>
    </div>


    <CModal :show.sync="openPrescriptionFlg"
            :centered="true"
            title="処方">
      <div>
        <CRow class="mt-0">
          <CCol>
            <CInput class="mb-1" horizontal label="安静時" v-model="prescriptionForm.rest"/>
          </CCol>
        </CRow>

        <CRow class="mt-0">
          <CCol>
            <CInput class="mb-1" horizontal label="労作時" v-model="prescriptionForm.exercise"/>
          </CCol>
        </CRow>

        <CRow class="mt-0">
          <CCol>
            <CInput class="mb-1" horizontal label="就寝時" v-model="prescriptionForm.sleep"/>
          </CCol>
        </CRow>
      </div>
      <template #footer>
        <CButton @click="openPrescriptionFlg = false" color="danger">キャンセル</CButton>
        <CButton @click="savePrescription()" color="success">OK</CButton>
      </template>
    </CModal>

    <div class="item item2 left-content mx-2">
      <div class="content">
        <div class="nestedItem left">
          <div class="patient-info mt-3">
            <div>
              <span style="width:105px;display: inline-block">ID</span>
              <span >{{ karuteNo }}</span>
            </div>

<!--            <div class="patient-info-item">ID</div>-->
<!--            <div class="patient-info-item">{{ karuteNo }}</div>-->
          </div>

<!--          <div class="patient-info no-print-area" >-->
<!--            <div class="patient-info-item"><div>氏名</div></div>-->
<!--            <div class="patient-info-item"><div>{{ patientName }}</div></div>-->
<!--          </div>-->


          <div>
            <span style="width: 105px;display: inline-block">氏名</span>
            <span style="width: 80%">{{ patientName }}</span>
          </div>



          <div class="patient-info no-print-area mt-3" >
            <div class="patient-info-item">処方流量(リットル/分)</div>
          </div>
          <div class="patient-info no-print-area" >
            <div class="patient-info-item ml-3 text-center">安静時</div>
            <div class="patient-info-item ml-3 text-center">労作時</div>
            <div class="patient-info-item ml-3 text-center">就寝時</div>
            <div class="patient-info-item print-invisible">
              <CButton size="sm" color="success mr-1"  @click="openPrescription()">処方入力</CButton>
            </div>
          </div>

          <div class="patient-info no-print-area" >
            <div class="patient-info-item ml-3 text-center">{{ prescription.rest }}</div>
            <div class="patient-info-item ml-3 text-center">{{ prescription.exercise }}</div>
            <div class="patient-info-item ml-3 text-center">{{ prescription.sleep }}</div>
            <div class="patient-info-item">&nbsp;</div>
          </div>
<!--          <CButton size="sm" color="success mr-1"  @click="getMeasureValueSummary()">getSummary</CButton>-->

<!--          style="border: solid 1px black"-->
          <table width="100%" class="mt-5" >
            <tr v-if="chartType == 'summary' || chartType == 'ox'">
              <td colspan="6" style="border:none;"><label style="font-weight: bold">酸素濃縮装置</label></td>
            </tr>
            <tr class="data-label" v-if="chartType == 'summary' || chartType == 'ox'">
              <td></td>
              <td style="border:solid 1px black;" class="text-center">最大値</td>
              <td class="text-center">最小値</td>
              <td class="text-center">平均値</td>
              <td class="text-center">中央値</td>
              <td class="text-center">標準偏差</td>
<!--              <td>閾値外頻度1</td>-->

<!--              <td colspan="2">装着時間(h)</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'ox'">
              <td ><div class="ml-2">流量（リットル/分）</div></td>
              <td class="text-center">{{ measureValueSummary.ox_setting["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_setting["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_setting["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_setting["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_setting["standardDeviation"] }}</td>
<!--              <td>{{ measureValueSummary.ox_setting["thresholdGai"] }}/{{ measureValueSummary.ox_setting["thresholdGaiBunshi"] }}</td>-->
<!--              <td></td>-->
<!--                      <td>{{ measureValueSummary.ox_setting["hour"] }}</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'ox'">
              <td ><div class="ml-2">分時呼吸数(回/分)</div></td>
              <td class="text-center">{{ measureValueSummary.ox_breath["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_breath["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_breath["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_breath["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_breath["standardDeviation"] }}</td>
<!--              <td>{{ measureValueSummary.ox_breath["thresholdGai"] }}/{{ measureValueSummary.ox_breath["thresholdGaiBunshi"] }}</td>-->
<!--              <td></td>-->
<!--                    <td>{{ measureValueSummary.ox_breath["hour"] }}</td>-->
            </tr>

            <tr v-if="chartType == 'summary' || chartType == 'ox'" >
<!--              <td></td>-->
              <td colspan="6" style="border: none;">
                <div class="text-center"><span></span>稼働時間:{{ measureValueSummary.ox_setting["hour"] }}{{oxHourMark}}<span class="ml-3">装着時間:{{ measureValueSummary.ox_breath["hour"]}}{{oxBreathHourMark}}</span><span class="ml-3">装着率:{{ souchakuritsu}}</span></div>
                <div  style="font-size:11px;">※酸素濃縮装置により検出された数値を表示していますので、</div>
                <div  style="font-size:11px;padding-left: 8px">実際の数値とは異なる場合があります。</div>
              </td>
            </tr>
<!--            <tr>-->
<!--              <td colspan="8" style="color: #FFF">.</td>-->
<!--            </tr>-->

            <tr>
              <td class="device" colspan="6" style="border:none;"><label style="color:transparent">-</label></td>
            </tr>

            <tr v-if="chartType == 'summary' || chartType == 'pu'" class="mt-3">
              <td class="device" colspan="6" style="border:none;"><label style="font-weight: bold">パルスオキシメータ</label></td>
            </tr>
            <tr class="data-label" v-if="chartType == 'summary' || chartType == 'pu'">
              <td></td>
              <td class="text-center">最大値</td>
              <td class="text-center">最小値</td>
              <td class="text-center">平均値</td>
              <td class="text-center">中央値</td>
              <td class="text-center">標準偏差</td>
<!--              <td>閾値外頻度</td>-->
<!--              <td>測定時間(m)</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'pu'">
              <td ><div class="ml-2">血中酸素飽和度(％)</div></td>
              <td class="text-center">{{ measureValueSummary.spo2["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.spo2["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.spo2["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.spo2["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.spo2["standardDeviation"] }}</td>
<!--              <td>{{ measureValueSummary.spo2["thresholdGai"] }}/{{ measureValueSummary.spo2["thresholdGaiBunshi"] }}</td>-->
<!--              <td>{{ measureValueSummary.spo2["hour"] }}</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'pu'">
              <td ><div class="ml-2">脈拍数(回/分)</div></td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["standardDeviation"] }}</td>
<!--              <td>{{ measureValueSummary.pr_bpm["thresholdGai"] }}/{{ measureValueSummary.pr_bpm["thresholdGaiBunshi"] }}</td>-->
<!--              <td>{{ measureValueSummary.pr_bpm["hour"] }}</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'pu'">
              <td colspan="7" style="border: none;"><div class="text-center">表示期間のパルスオキシメータ測定回数 {{ measureValueSummary.pr_bpm["measureCount"] }} 回</div></td>
            </tr>

<!--            <tr>-->
<!--              <td colspan="8" style="color: #FFF">.</td>-->
<!--            </tr>-->
            <tr v-if="chartType == 'bl'">
              <td class="device" colspan="6" style="border: none;"><label style="font-weight: bold">血圧計</label></td>
            </tr>
            <tr class="data-label" v-if="chartType == 'bl'">
              <td></td>
              <td class="text-center">最大値</td>
              <td class="text-center">最小値</td>
              <td class="text-center">平均値</td>
              <td class="text-center">中央値</td>
              <td class="text-center">標準偏差</td>
<!--              <td>閾値外頻度</td>-->
<!--              <td class="text-center">測定回数</td>-->
            </tr>
            <tr v-if="chartType == 'bl'">
              <td ><div class="ml-2">収縮期血圧(mmHg)</div></td>
              <td class="text-center">{{ measureValueSummary.bl_up["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_up["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_up["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_up["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_up["standardDeviation"] }}</td>
<!--              <td>{{ measureValueSummary.bl_up["thresholdGai"] }}/{{ measureValueSummary.bl_up["thresholdGaiBunshi"] }}</td>-->
<!--              <td rowspan="3"><div class="text-center">{{ measureValueSummary.bl_up["hour"] }}</div></td>-->
            </tr>
            <tr v-if="chartType == 'bl'">
              <td ><div class="ml-2">拡張期血圧(mmHg)</div></td>
              <td class="text-center">{{ measureValueSummary.bl_down["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_down["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_down["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_down["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_down["standardDeviation"] }}</td>
<!--              <td>{{ measureValueSummary.bl_down["thresholdGai"] }}/{{ measureValueSummary.bl_down["thresholdGaiBunshi"] }}</td>-->
<!--              <td>{{ measureValueSummary.bl_down["hour"] }}</td>-->
            </tr>

            <tr v-if="chartType == 'bl'">
              <td ><div class="ml-2">脈拍(回/分)</div></td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["max"] || '' }}</td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["standardDeviation"] }}</td>
<!--              <td>{{ measureValueSummary.bl_bpm["thresholdGai"] }}/{{ measureValueSummary.bl_bpm["thresholdGaiBunshi"] }}</td>-->
<!--              <td>{{ measureValueSummary.bl_bpm["hour"] }}</td>-->
            </tr>
            <tr v-if="chartType == 'bl'">
              <td colspan="6" style="border: none;"><div class="text-center">表示期間の血圧計の測定回数 {{ measureValueSummary.bl_up["hour"] }} 回</div></td>
            </tr>
          </table>

          <div>
            <label for="memo" class="mt-2 mb-0">メモ</label>
            <LimitedTextArea id="memo" @input="memo = $event" maxlength="200"  placeholder="メモを入力してください。"
                             rows="6" style="width:100%;height:150px;resize: none;">
            </LimitedTextArea>
          </div>
        </div>

        <div class="nestedItem right">
          <div v-if="chartType == 'summary'">
            <div class="chart chart1">
              <ox-charts :day-count="dayCount"  :from-series="serieObject['SUMMARY_OX_SETTING']"
                         :chart-width="widthHeight.OX_SETTING_WIDTH"
                         :x-title="oxChartXTitle" :y-title="oxChartYTitle" :day-from-to="dayFromTo"></ox-charts>
            </div>
            <!-- 呼吸数 -->
            <div class="chart chart2">
              <BreathCharts v-if="dayCount < 30" :day-count="dayCount"
                            :from-series="serieObject['SUMMARY_OX_BREATH_17']" :day-from-to="dayFromTo" chart-title="呼吸数" :ymin="10" :ymax="35"
                            :x-title="breath17XTitle" y-title="回/分"/>
              <BreathCharts30 v-else :day-count="dayCount" :from-series="serieObject['SUMMARY_OX_BREATH_3090']"
                              :day-from-to="dayFromTo" chart-title="呼吸数" :ymin="10" :ymax="35"
                              x-title="日付" y-title="回/分"/>
            </div>
            <!-- 血中酸素飽和度○/脈拍数 -->
            <div class="chart chart3">
              <Spo2Charts v-if="dayCount < 30" :day-count="dayCount" chart-title="血中酸素飽和度/脈拍数"
                          :from-series="serieObject['SUMMARY_PU_SPO2_17']" :day-from-to="dayFromTo" :ymin="getSpo2YaxisPointMin(serieObject['SUMMARY_PU_SPO2_17'],80)" :ymax="100"
                          :x-title="spo17Title" y-title="%" :colors="['#6600FF']"></Spo2Charts>
              <BreathCharts30 v-else :day-count="dayCount" chart-title="血中酸素飽和度/脈拍数" :from-series="serieObject['SUMMARY_PU_SPO2_3090']"
                              :day-from-to="dayFromTo" :ymin="getSpo2YaxisMin(serieObject['SUMMARY_PU_SPO2_3090'],80)" :ymax="100"
                              x-title="日付" y-title="%" :colors="['#6600FF']"/>
            </div>
            <!-- 血中酸素飽和度/脈拍数○ -->
            <div class="chart chart4" >
              <PulseCharts v-if="dayCount < 30" :day-count="dayCount"
                           :from-series="serieObject['SUMMARY_PU_MYAKUHAKU_17']" :day-from-to="dayFromTo"
                           :ymin="getMyakuhakuYaxisPointMin(serieObject['SUMMARY_PU_MYAKUHAKU_17'],60)"
                           :ymax="getMyakuhakuYaxisPointMax(serieObject['SUMMARY_PU_MYAKUHAKU_17'],180)"
                           :x-title="spo17Title" y-title="回/分" :colors="['#FF0000']"
              />
              <BreathCharts30 v-else style="margin-top: -40px" :day-count="dayCount" chart-title="" :from-series="serieObject['SUMMARY_PU_MYAKUHAKU_3090']"
                              :day-from-to="dayFromTo" :ymin="getMyakuhakuYaxisMin(serieObject['SUMMARY_PU_MYAKUHAKU_3090'],60)" :ymax="getMyakuhakuYaxisMax(serieObject['SUMMARY_PU_MYAKUHAKU_3090'],180)" :show-xasis="false" :colors="['#FF0000']"
                              y-title="回/分"/>
            </div>
          </div>

          <!-- 酸素吸入-->
          <div v-show="chartType == 'ox'">
            <div class="chart chart1">
              <ox-charts :day-count="dayCount"  :from-series="serieObject['OX_SETTING']"
                :chart-width="widthHeight.OX_SETTING_WIDTH"
                :x-title="oxChartXTitle" :y-title="oxChartYTitle" :day-from-to="dayFromTo"/>
            </div>
            <div class="chart chart2">
              <BreathCharts v-if="dayCount < 30" :day-count="dayCount" :from-series="serieObject['OX_BREATH_17']"
                            chart-title="呼吸数" :day-from-to="dayFromTo" :ymin="10" :ymax="35"
                            :x-title="breath17XTitle" y-title="回/分"/>
              <BreathCharts30 v-else  :day-count="dayCount" :from-series="serieObject['OX_BREATH_3090']"
                              :day-from-to="dayFromTo" chart-title="呼吸数" :ymin="10" :ymax="35"
                              x-title="日付" y-title="回/分"/>
            </div>
            <div class="chart chart2 half" >
              <div >
                <BreathPie :day-count="dayCount" chart-title="設定流量の割合"
                            :from-series-lables="serieObject['OX_PIE']"/>
              </div>
              <div>
                <BreathColumn :day-count="dayCount" chart-title="呼吸数の分布"
                              :from-series-and-category="serieObject['OX_BREATH_COLUMN_DATA']"
                              x-title="呼吸数(回/分)" y-title="頻度(回)"/>
              </div>
            </div>
          </div>
          <!-- SPO2-->
          <div v-show="chartType == 'pu'">
            <div class="chart chart3">
              <Spo2Charts v-if="dayCount < 30" :day-count="dayCount" chart-title="血中酸素飽和度/脈拍数"
                          :from-series="serieObject['PU_SPO2_17']" :day-from-to="dayFromTo" :ymin="getSpo2YaxisPointMin(serieObject['PU_SPO2_17'],80)" :ymax="100"
                          :x-title="spo17Title" y-title="%" :colors="['#6600FF']"/>
              <BreathCharts30 v-else :day-count="dayCount" chart-title="血中酸素飽和度/脈拍数" :from-series="serieObject['PU_SPO2_3090']"
                              :day-from-to="dayFromTo" :ymin="getSpo2YaxisMin(serieObject['PU_SPO2_3090'],80)" :ymax="100"
                              x-title="日付" y-title="%" :colors="['#6600FF']"/>

              <PulseCharts v-if="dayCount < 30" :day-count="dayCount" chart-title="" style="margin-top: -20px"
                          :from-series="serieObject['PU_MYAKUHAKU_17']" :day-from-to="dayFromTo"
                           :ymin="getMyakuhakuYaxisPointMin(serieObject['PU_MYAKUHAKU_17'],60)"
                           :ymax="getMyakuhakuYaxisPointMax(serieObject['PU_MYAKUHAKU_17'],180)"
                           y-title="回/分" :colors="['#FF0000']"/>
              <BreathCharts30 v-else :day-count="dayCount" chart-title="" :colors="['#FF0000']" :from-series="serieObject['PU_MYAKUHAKU_3090']"
                              y-title="回/分" :day-from-to="dayFromTo" :ymin="getMyakuhakuYaxisMin(serieObject['PU_MYAKUHAKU_3090'],60)" :ymax="getMyakuhakuYaxisMax(serieObject['PU_MYAKUHAKU_3090'],180)" />
            </div>

            <div class="chart chart2 half" >
              <div>
                <BreathColumn :day-count="dayCount" chart-title="血中酸素飽和度の分布"
                               :from-series-and-category="serieObject['PU_SPO2_COLUMN_DATA']" :ymin="80" :ymax="100" :tickAmount="1"
                x-title="血中酸素飽和度(%)" y-title="頻度(回)" :colors="['#6600FF']"/>
              </div>
              <div>
                <BreathColumn :day-count="dayCount" chart-title="脈拍数の分布" x-title="脈拍数(回/分)" y-title="頻度(回)"
                              :from-series-and-category="serieObject['PU_MYAKUHAKU_COLUMN_DATA']" :ymin="60" :ymax="180" :colors="['#FF0000']"/>
              </div>
            </div>
          </div>
          <!-- 血圧 -->
          <div v-show="chartType== 'bl'">
            <div class="chart chart2">
              <Spo2Charts v-if="dayCount < 30" :day-count="dayCount" chart-title="血圧" chart-height="400"
                          :from-series="serieObject['BL_17']" :day-from-to="dayFromTo" :ymin="60" :ymax="180" :colors="['#FF33CC','#3399FF']"
                          :x-title="bl17Title" y-title="mmHg"/>
              <BreathCharts30 v-else :day-count="dayCount" chart-title="血圧" chart-height="400"
                              :from-series="serieObject['BL_3090']" :day-from-to="dayFromTo" :ymin="60" :ymax="180" :colors="['#FF33CC','#3399FF']"
                              x-title="日付" y-title="mmHg"/>
            </div>

            <div class="chart chart2">
              <Spo2Charts v-if="dayCount < 30" :day-count="dayCount" chart-title="脈拍" chart-height="400"
                          :from-series="serieObject['BL_HEART_17']" :day-from-to="dayFromTo" :ymin="60" :ymax="180" :colors="['#FF0000']"
                          :x-title="bl17Title" y-title="回/分"/>
              <BreathCharts30 v-else  :day-count="dayCount" chart-title="脈拍" chart-height="400"
                              :from-series="serieObject['BL_HEART_3090']" :day-from-to="dayFromTo" :ymin="60" :ymax="180" :colors="['#FF0000']"
                              x-title="日付" y-title="回/分"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import {ja} from "vuejs-datepicker/dist/locale";
import moment from "moment";
import OxCharts from "@/component/parts/charts/OxCharts";
import BreathCharts from "@/component/parts/charts/BreathCharts";
import BreathCharts30 from "@/component/parts/charts/BreathCharts30";
import Spo2Charts from "@/component/parts/charts/Spo2Charts";
import PulseCharts from "@/component/parts/charts/PulseCharts";
import BreathPie from "@/component/parts/charts/BreathPie";
import BreathColumn from "@/component/parts/charts/BreathColumn";

import LimitedTextArea from "@/component/parts/LimitedTextArea";



export default {
  name: 'PatientSummaryWide',
  components: {Spo2Charts, BreathCharts,BreathCharts30
    , datepicker,OxCharts,PulseCharts,BreathPie,BreathColumn,LimitedTextArea},
  data: function () {
    return {
      memo:"",
      memoEditable:true,
      patientId:this.$route.params.id,
      dayCount:90,
      chartType: "summary",
      chartTo:null,
      chartToTmp:null,
      patientName:null,
      karuteNo:null,
      prescription:{rest:'',exercise:'',sleep:''},
      prescriptionForm:{rest:null,exercise:null,sleep:null,patientId:null},
      openPrescriptionFlg:false,
      //左側のサーまり
      measureValueSummary:{
        ox_setting:{},
        ox_breath:{},
        spo2:{},
        pr_bpm:{},
        bl_up:{},
        bl_down:{},
        bl_bpm:{}
      },
      dayFromTo:{from:0,to:0},
      serieObject: {
        // SUMMARY_OX_SETTING:[],
        // SUMMARY_OX_BREATH_17:[],
        // SUMMARY_OX_BREATH_3090:[],
        //
        // OX_SETTING : [],
        //
        // SPO2_17:[],
        // SPO2_3090:[],
        // PU_MYAKUHAKU:[],
        // PU_SPO2:[],
        // BL_3090 : [],
        // BL_17 : [],
        // BL_HEART_17 : [],
        // BL_HEART_3090 : [],
        // OX_BREATH_17 : [],
        // OX_BREATH_3090 : [],
        OX_PIE : { series: [],labels: [] },
        OX_BREATH_COLUMN_DATA:{category:[],data:[]},
        PU_SPO2_COLUMN_DATA:{category: [], data:[]},
        PU_MYAKUHAKU_COLUMN_DATA:{category: [], data:[]},
      },
      widthHeight:{
        OX_SETTING_WIDTH:'100%',

        SPO_17_WIDTH:"98%",
        SPO_17_HEIGHT:"200",

        OX_17_WIDTH:'98%',
        OX_BREATH_17_WIDTH:'98%',
        OX_PIE_WIDTH:'98%',
        OX_BREATH_COLUMN_WIDTH:'98%',
        BL_17_WIDTH:'98%'/**/,
        BL_17_HEIGHT:440/*'360'*/
      },
      oxChartXTitle:"時刻",
      oxChartYTitle:"分",
      breath17XTitle: "時刻",
      spo17Title : "時刻",
      bl17Title : "時刻"
    }
  },

  methods: {
    getSpo2YaxisMin(ser,defaultMin){
      if(ser === undefined || ser.length == 0){
        return defaultMin;
      }
      let minList = [];
      let maxList = [];
      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i];

        let min = d["y"][0];
        let max = d["y"][1];
        if(min > 0){
          minList.push(min);
        }
        if(max > 0){
          maxList.push(max);
        }
      }
      if(minList.length < 1){
        return defaultMin;
      }

      let min = minList.reduce(function (p, v) {
        return ( p < v ? p : v );
      })
      return min-5;
    },
    getSpo2YaxisPointMin(ser,defaultMin){
      if(ser === undefined || ser.length == 0){
        return defaultMin;
      }
      let list = [];

      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i][1];
        if(d > 0){
          list.push(d);
        }
      }
      if(list.length < 1){
        return defaultMin;
      }

      let min = list.reduce(function (p, v) {
        return ( p < v ? p : v );
      });
      return min-5;
    },
    getMyakuhakuYaxisMin(ser,defaultMin){
      if(ser === undefined || ser.length == 0){
        return defaultMin;
      }
      let minList = [];
      let maxList = [];
      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i];

        let min = d["y"][0];
        let max = d["y"][1];
        if(min > 0){
          minList.push(min);
        }
        if(max > 0){
          maxList.push(max);
        }
      }
      if(minList.length < 1){
        return defaultMin;
      }

      let min = minList.reduce(function (p, v) {
        return ( p < v ? p : v );
      })
      return min-5;
    },
    getMyakuhakuYaxisMax(ser,defaultMax){
      if(ser === undefined || ser.length == 0){
        return defaultMax;
      }
      let minList = [];
      let maxList = [];
      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i];

        let min = d["y"][0];
        let max = d["y"][1];
        if(min > 0){
          minList.push(min);
        }
        if(max > 0){
          maxList.push(max);
        }
      }
      if(minList.length < 1){
        return defaultMax;
      }
      let max= maxList.reduce(function (p, v) {
        return ( p > v ? p : v );
      })
      return max+5;
    },
    getMyakuhakuYaxisPointMin(ser,defaultMin){
      if(ser === undefined || ser.length == 0){
        return defaultMin;
      }
      let list = [];

      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i][1];
        if(d > 0){
          list.push(d);
        }
      }
      if(list.length < 1){
        return defaultMin;
      }
      let min= list.reduce(function (p, v) {
        return ( p < v ? p : v );
      })
      return min-5;
    },
    getMyakuhakuYaxisPointMax(ser,defaultMax){
      if(ser === undefined || ser.length == 0){
        return defaultMax;
      }
      let list = [];

      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i][1];
        if(d > 0){
          list.push(d);
        }
      }
      if(list.length < 1){
        return defaultMax;
      }
      let max= list.reduce(function (p, v) {
        return ( p > v ? p : v );
      })
      return max+5;
    },
    chartPrint(){
      this.$store.commit("setMemo", this.memo);
      window.open(`/#/PatientSummaryPrint/${this.patientId}/${this.chartType}/${this.dayCount}/${this.chartTo}`);
    },
    getData(){
      // this.getMeasureValueSummary();
      let url = `/api/measureValue/getAllChart/${this.patientId}/${this.chartType}/${this.dayCount}/${this.chartTo}`;
      if(this.prescription !== ''){
        url = url+"?patientInfo=true"
      }
      this.axios.get(url)
          .then(response => {
            for (let key in response.data.result) {
              if(key == 'patientSummary'){
                this.measureValueSummary = Object.assign({},this.measureValueSummary,response.data.result[key]);
              }else if(key == 'patientInfo') {
                this.prescription = response.data.result[key]["prescription"];
                this.patientName = response.data.result[key]["patientName"];
                this.karuteNo = response.data.result[key]["karuteNo"];
              }else{
                this.serieObject[key] = response.data.result[key];
              }
            }
            this.dayFromTo.from =
                moment(`${this.chartTo} 00:00:00`).add(-this.dayCount+1,"d").format("YYYY-MM-DD HH:mm:ss");
            this.dayFromTo.to =
                moment(`${this.chartTo} 00:00:00`).add(1,"d").format("YYYY-MM-DD HH:mm:ss");

            if(this.chartType == "summary" ||this.chartType == "ox" ){
              if(this.dayCount == 1){
                this.oxChartXTitle= "時刻";
                this.oxChartYTitle = "分";
              }else{
                this.oxChartXTitle= "日付";
                this.oxChartYTitle = "時刻";
              }
            }

            if(this.chartType == "summary" || this.chartType == "ox" || this.chartType == "pu" ){
              if(this.dayCount == 1){
                this.breath17XTitle= "時刻";
                this.spo17Title = "時刻";
                this.bl17Title = "時刻";
              }else{
                this.breath17XTitle= "日付";
                this.spo17Title  = "日付";
                this.bl17Title = "日付";
              }
            }
            if(this.dayCount == 7 && (this.chartType == 'summary' || this.chartType == 'ox')){
              this.OX_SETTING_WIDTH = '100%';
            }else{
              this.OX_SETTING_WIDTH = '90%';
            }

          }).catch(error => {console.log(error)})
          .finally(() => {});
    },csvDownload() {
        if(this.chartType == "summary"){
            return;
        }
      // headers: {'Content-Type': 'application/json'},
      //   this.isLoading = true;
        this.axios.get(`/api/measureValue/download/measure_csv/${this.patientId}/${this.chartType}/${this.dayCount}/${this.chartTo}`
            , { responseType: 'blob'}).then(
            response => {
              let url = window.URL.createObjectURL(new Blob([response.data]));
              let link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', decodeURI(response.headers.filename));
              document.body.appendChild(link);
              link.click();

            }).catch(error => console.log(error)
        );
    }
    ,getMeasureValueSummary(){
      // if(this.patientId == null){
      //   return;
      // }
      this.axios.get(`/api/get_measure_value_summary/${this.patientId}/${this.dayCount}/${this.chartTo}`)
          .then(response => {

            this.measureValueSummary = Object.assign({},this.measureValueSummary,response.data.result);

          }).catch(error => {console.log(error)})
          .finally(() => {
          });
    },
    savePrescription(){
      this.axios.post('/api/save_prescription', this.prescriptionForm, {headers: {'Content-Type': 'application/json'}}).then(response => {
        if (response.data.result) {
          this.prescription = response.data.result;
          this.prescriptionForm = Object.assign({patientId: this.patientId,doctorId:this.$store.state.user.id},this.prescription);
        }
        this.openPrescriptionFlg = false;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);

    },
    // getPatientInfo(){
    //    this.axios.get(`/api/patient_info/${this.patientId}`).then(response => {
    //     if(response.data.result){
    //       this.prescription = response.data.result.prescription;
    //       this.patientName = response.data.result.patientName;
    //       this.karuteNo = response.data.result.karuteNo;
    //     }
    //   }).catch(error => console.log(error)
    //   ).finally(() => this.isLoading = false);
    // },
    chartDateChange:function(day){
      this.dayCount = day;
    },
    openPrescription(){
      this.openPrescriptionFlg = true;
      this.prescriptionForm = Object.assign({patientId: this.patientId,doctorId:this.$store.state.user.id},this.prescription);
    },
  },
  watch: {
    chartToTmp: function () {
      if (this.chartToTmp) {
        this.chartTo = moment(this.chartToTmp).format('YYYY-MM-DD');
        this.getData();
      }
    },
    chartType(){
      this.getData();
    },
    dayCount() {
      this.getData();
    },
  },
  computed:{
    ja() { return ja;  },
    souchakuritsu(){
      let result = (this.measureValueSummary.ox_breath["hour"] / this.measureValueSummary.ox_setting["hour"] * 100).toFixed(1);
      if(isNaN(result) || result == Infinity){
        return "-";
      }
      return result+"%";
    },
    oxHourMark(){
      return isNaN(this.measureValueSummary.ox_setting["hour"]) ? "" : "h";

    },
    oxBreathHourMark(){
      return isNaN(this.measureValueSummary.ox_breath["hour"]) ? "" : "h";
    }
  },
  mounted(){
    this.patientId = this.$route.params.id;
    this.chartToTmp = moment().format("YYYY-MM-DD");
    // this.chartToTmp = '2021-05-06';
    //グラフ用のデータシュトック
    // this.getData();
    //患者情報取得
    // this.getPatientInfo();
    //左側の患者バイタル情報取得
    // this.getMeasureValueSummary();
  }
}
</script>

<style>
body, html{
  height:100%;
  margin: 0px;
}
.my-container{
  /*background:beige;*/
  height:100%;
}

.item{
  /* width:120px;
  height:120px; */
  /* border:1px solid black; */
  margin-left: 10px;
}
.tool-bar{
  height:4vh;
  margin: 0;
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.item2{
  /*background: #f48fb1;*/
  height:96vh;
  margin: 0;
  padding:0;
}
.content{
  display:flex;
  flex-direction: row;
  padding-top: 10px;
  height:100%;
  font-size: 1rem;
  color:black;
}
.nestedItem.left{
  background: white;
  height:100%;
  /*flex-grow: 1;*/
  flex-basis: 40%;
  max-width:40%;
  pading-left: 20px;
  min-width: 650px;

}
.nestedItem.right{
  /*background: violet;*/
  height:100%;
  /*flex-grow: 3;*/
  flex-basis: 60%;
  /*max-width:60%;*/
  display:flex;
  flex-direction: column;
}


.patient-info{
  display: flex;
  /*justify-content: ;*/
}
.patient-info-item {
  /*flex-grow: 1;*/
  /*border:solid 1px red;*/
  flex-basis: 50%;
  /*font-size: 18px;*/
  font-family: bold;
  color:black;
}
.chart{
  /*flex-grow: 1;*/
  flex-basis: auto;
}

.patient-info-table{
  font-size: 1rem
}
.chart.half{
  display:flex;
}
.chart.half > div{
  flex-basis: 50%;
}
.print-invisible{
  display: block;
}
.solid {
  border:solid 1px black;
}
.solid tr,td {
  border:solid 1px black;
}
/*.chart1{*/
/*  background: #99a4a1;*/
/*}*/
/*.chart2{*/
/*  background: #c7a0cb;*/
/*}*/
/*.chart3{*/
/*  background: gray;*/
/*}*/
/*.chart4{*/
/*  background: #84728a;*/
/*}*/

</style>